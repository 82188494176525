import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UserService } from '@app/core/services/user.service';
import { ModuleListRequest } from '@app/lib/api/user/api.user.model';
import { CurrentPlace } from '@app/modules/main/user-region/user-region.component';
import { ModuleType } from '@app/shared/const';
import { TreeNode } from 'primeng/api';
import * as _ from 'lodash';

export interface SelectedModule {
  parent_module: {
    name: string;
    value: string;
  };
  module: {
    name: string;
    value: string;
  }
}
@Component({
  selector: 'app-module-list',
  templateUrl: './module-list.component.html',
  styleUrls: ['./module-list.component.scss']
})
export class ModuleListComponent implements OnChanges {
  data: TreeNode[] = [];
  selectedData: TreeNode;
  isLoading = false;
  @Input() currentPlace: CurrentPlace;
  @Output() selectModule = new EventEmitter();

  constructor(
    private userService: UserService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentPlace'] && changes['currentPlace'].currentValue) {
      if (_.isEqual(changes['currentPlace'].currentValue, changes['currentPlace'].previousValue)) {
        return;
      }
      this.getModuleData();
    }
  }

  nodeExpand(event: any) {
    if (event.node && !event.node.children.length) {
      const body: ModuleListRequest = {
        country_code: this.currentPlace.country_code,
        region: this.currentPlace.region || '',
        city: this.currentPlace.city || '',
        type: event.node.data,
        domain: 'ASSIGNMENT'
      };
      this.userService.getModuleList(body).subscribe(res => {
        if (res.module_item.length) {
          res.module_item.forEach(el => {
            const item: TreeNode = {
              key: el.value,
              label: el.label,
              data: el.value
            };
            event.node.children.push(item);
          });
        }
      });
    }
  }

  getModuleData() {
    this.isLoading = true;
    let body: ModuleListRequest = {
      country_code: this.currentPlace.country_code,
      region: this.currentPlace.region || '',
      city: this.currentPlace.city || '',
      type: ModuleType.all,
      domain: 'ASSIGNMENT'
    };
    this.data = [];
    this.userService.getModuleList(body).subscribe(res => {
      if (res?.module_item.length) {
        res.module_item.forEach((el, i) => {
          let childrenData = el.children.map(el => { return {
            key: el.value,
            label: el.label,
            data: el.value
          }});
          const item: TreeNode = {
            key: el.value,
            label: el.label,
            data: el.value,
            leaf: false,
            children: childrenData,
            expanded: i === 0 ? true : false
          };
          this.data.push(item);
          if (i !== 0) return;
          if (this.data && this.data[0].children?.length) {
            this.selectedData = this.data[0].children[0];
            const selectedModule: SelectedModule = {
              parent_module: {
                name: this.data[0].label || '',
                value: this.data[0].key || ''
              },
              module: {
                name: this.data[0].children[0].label || '',
                value: this.data[0].children[0].key || ''
              }
            }
            this.selectModule.emit({ selectedModule: selectedModule, initData: true });
          }
        });
      } else {
        this.selectModule.emit(null);
      }
      this.isLoading = false;
    });
  }

  nodeSelect(event: any) {
    if (event.node && event.node.children) {
      event.node.expanded = !event.node.expanded;
      this.nodeExpand(event);
    } else {
      this.selectedData = event.node;
      const selectedModule: SelectedModule = {
        parent_module: {
          name: event.node.parent.label,
          value: event.node.parent.key
        },
        module: {
          name: event.node.label,
          value: event.node.key
        }
      }
      this.selectModule.emit({ selectedModule: selectedModule, initData: false });
    }
  }
}
