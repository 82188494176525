import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { ApiClientConfig } from '../api-client.config';
import { AuthAPI } from '../auth/api.auth.model';

export class AuthApi {
  private apiUrl: string = environment.API_URL;
  AUTH = '/auth';

  constructor(
    public readonly http: HttpClient,
    public config: ApiClientConfig
  ) {}

  signIn(data: AuthAPI.SignIn): Observable<AuthAPI.SignIn> {
    return this.http.post<AuthAPI.SignIn>(`${this.apiUrl}/user/admin/authenticate`, data).pipe(
      timeout(this.config.responseTimeout),
      catchError(error => {
        throw error.error;
      })
    );
  }

  signOut(data: AuthAPI.SignOut): Observable<AuthAPI.SignOut> {
    return this.http.post<AuthAPI.SignOut>(`${this.apiUrl}${this.AUTH}/signout`, data).pipe(
      timeout(this.config.responseTimeout),
      catchError(error => {
        throw error.error;
      })
    );
  }

  thirdPartySignIn(data: AuthAPI.ThirdPartySignIn): Observable<AuthAPI.ThirdPartySignIn> {
    return this.http
      .post<AuthAPI.ThirdPartySignIn>(`${this.apiUrl}${this.AUTH}/third-party/signin`, data)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getDetailInfo(): Observable<AuthAPI.Regional> {
    return this.http.get<AuthAPI.Regional>(`${this.apiUrl}/regional/me`).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }
}
