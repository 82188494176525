import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApiClient } from '@app/lib/api/api-client';
import {
  AddModeratorRequest,
  ModeratedCity,
  ModeratedCountry,
  ModeratedRegion,
  Moderator,
  ModeratorListRequest,
  ModeratorResponse,
  ModeratorRole,
  ModeratorRoleRequest,
  ModuleList,
  ModuleListRequest,
  UserInfoResponse,
  UserManagementResponse
} from '@app/lib/api/user/api.user.model';
import { environment } from '@env/environment';
import { ResponseObject } from '@app/shared/models/response-object';
import { EditModeratorRequest } from '@app/shared/models/users-management';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userInfo = new BehaviorSubject<any>({});

  private indexTab: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  getIndexTab(): Observable<number> {
    return this.indexTab.asObservable();
  }

  setIndexTab(newState: number): void {
    this.indexTab.next(newState);
  }

  private api: ApiClient;
  constructor(private http: HttpClient) {
    this.api = new ApiClient(this.http, { responseTimeout: environment.API_TIMEOUT });
  }

  getUserById(id: string): Observable<UserInfoResponse> {
    return this.api.user.getUserById(id);
  }

  getUserList(
    pageNum: number,
    pageSize: number,
    country_code: string,
    region: string,
    city: string,
    textSearch: string,
    status: string,
    roles: string
  ): Observable<UserManagementResponse> {
    return this.api.user.getUserList(pageNum, pageSize, country_code, region, city, textSearch, status, roles);
  }

  getCountries(): Observable<any> {
    return this.api.user.getCountries();
  }

  getModerateRole(body: ModeratorRoleRequest): Observable<string> {
    return this.api.user.getModerateRole(body);
  }

  addModerator(body: AddModeratorRequest): Observable<ResponseObject> {
    return this.api.user.addModerator(body);
  }

  getModerators(
    pageNum = 0,
    pageSize = 10,
    param: ModeratorListRequest,
    textSearch: string,
    status: string,
    roles: string
  ): Observable<ModeratorResponse> {
    return this.api.user.getModerators(pageNum, pageSize, param, textSearch, status, roles);
  }

  getModuleList(body: ModuleListRequest): Observable<ModuleList> {
    return this.api.user.getModuleList(body);
  }

  getModeratorRole(): Observable<ModeratorRole[]> {
    return this.api.user.getModeratorRole();
  }

  getModeratedCountries(userId: string): Observable<ModeratedCountry[]> {
    return this.api.user.getModeratedCountries(userId);
  }

  getModeratedRegion(userId: string, country_code: string): Observable<ModeratedRegion[]> {
    return this.api.user.getModeratedRegion(userId, country_code);
  }

  getModeratedCity(userId: string, region: string): Observable<ModeratedCity[]> {
    return this.api.user.getModeratedCity(userId, region);
  }

  searchModerators(
    pageNum = 0,
    pageSize = 10,
    param: ModeratorListRequest,
    textSearch: string,
    status: string,
    roles: string
  ): Observable<ModeratorResponse> {
    return this.api.user.searchModerators(pageNum, pageSize, param, textSearch, status, roles);
  }

  deleteModerator(body: AddModeratorRequest): Observable<ResponseObject>  {
    return this.api.user.deleteModerator(body);
  }

  replaceModerator(body: AddModeratorRequest): Observable<ResponseObject>  {
    return this.api.user.replaceModerator(body);
  }

  editRoleModerator(body: EditModeratorRequest): Observable<ResponseObject>  {
    return this.api.user.editRoleModerator(body);
  }

  getUsersExcludeModerator(
    pageNum: number,
    pageSize: number,
    currentModule: any,
    currentLocation: any,
    textSearch?: string
  ): Observable<ResponseObject> {
    return this.api.user.getUsersExcludeModerator(pageNum, pageSize, currentModule, currentLocation, textSearch);
  }
}
