import { Injectable } from '@angular/core';
import { TOAST_MESSAGE_LIFETIME_DURATIONS } from '@app/shared/const';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(private messageService: MessageService) {}

  addToastMessage(severity: string, detail: string, lifeTime = TOAST_MESSAGE_LIFETIME_DURATIONS.medium): void {
    this.messageService.add({
      severity: severity,
      detail: detail,
      life: lifeTime
    });
  }
}
