import { environment } from "@env/environment";
import { ApiClientConfig } from "../api-client.config";
import { HttpClient } from "@angular/common/http";
import { Observable, catchError, map, timeout } from "rxjs";
import { CityModel, CityModelResponse, CountryModel, CountryModelResponse, RegionModel, RegionModelResponse, RegionalStatisticsModel, RegionalStatisticsModelResponse } from "./api.country.model";

export class CountryApi {
  apiUrl = environment.API_URL;
  REGIONAL = '/regional';

  constructor(
    public readonly http: HttpClient,
    public config: ApiClientConfig
  ) {}


  getCountry(pageNum: number, pageSize: number): Observable<CountryModel[]> {
    return this.http.get<CountryModelResponse>(`${this.apiUrl}${this.REGIONAL}/regional/country?pageNum=${pageNum}&pageSize=${pageSize}`).pipe(
      timeout(this.config.responseTimeout),
      map((res: CountryModelResponse) => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getRegion(country_code: string): Observable<RegionModel[]>{
    return this.http.get<RegionModelResponse>(`${this.apiUrl}${this.REGIONAL}/regional/${country_code}/region`).pipe(
      timeout(this.config.responseTimeout),
      map((res: RegionModelResponse) => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getCities(region: string): Observable<CityModel[]>{
    return this.http.get<CityModelResponse>(`${this.apiUrl}${this.REGIONAL}/regional/${region}/cities`).pipe(
      timeout(this.config.responseTimeout),
      map((res: CityModelResponse) => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getRegionalStatistics(region: string): Observable<RegionalStatisticsModel>{
    return this.http.get<RegionalStatisticsModelResponse>(`${this.apiUrl}${this.REGIONAL}/admin/${region}/statistics`).pipe(
      timeout(this.config.responseTimeout),
      map((res: RegionalStatisticsModelResponse) => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }
}