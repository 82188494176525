import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { UserInfo } from '@app/shared/models/user';
import { environment } from '@env/environment';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnChanges {
  @Input() readOnly = false;
  @Input() userInfo?: UserInfo;
  @Input() isSmallThumbnail = false;
  @Input() isDisableNavigate = false;
  @Input() isShowFrame = true;
  @Input() avatarSize = 40;
  userAvatarUrl = '';
  userFrameUrl = '';
  baseUrl: string = environment.API_URL;
  constructor(
    private router: Router
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['userInfo'].currentValue) {
      setTimeout(() => {
        const userInfo = changes['userInfo'].currentValue;
        this.getAvatar(userInfo);
        this.getFrame(userInfo);
      }, 500);
    }
  }

  ngOnInit(): void {
    this.getAvatar(this.userInfo);
    this.getFrame(this.userInfo);
  }

  navigateToProfile(): void {
    if (!this.isDisableNavigate && !this.readOnly) {
      this.router.navigate(['/user-detail/', this.userInfo?.id]);
    }
  }

  getAvatar(userInfo: any) {
    this.userAvatarUrl = this.getImageUrl(userInfo && userInfo.avatar_thumbnail_url);
  }

  getFrame(userInfo: any) {
    this.userFrameUrl = this.getImageUrl(userInfo && userInfo.frame_url);
  }

  getImageUrl(id: string | undefined): string {
    if (!id) {
      return '';
    }
    const isPath = id.startsWith('/');
    const webPath = isPath ? '' : '/storage/files/web/';
    const addWebp = isPath ? '' : '.webp';

    return `${this.baseUrl}${webPath}${id}${addWebp}`;
  }
}
