import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { AppConnectMessageType } from './core/enums/app-connect.enum';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = environment.APP_NAME;

  adminHubConnectUrl: string = environment.ADMIN_HUB_URL + '/app-connect';
  constructor(
    private router: Router
  ) {
    this.connectAdminHub();
  }

  @HostListener('window:message', ['$event'])
  receiveMessage(event: any) {
    if (
      event.data.type === AppConnectMessageType.AUTHORIZED &&
      event.origin === environment.ADMIN_HUB_URL
    ) {
      if (!event.data || !event.data.token) {
        window.location.href = environment.ADMIN_HUB_URL;
        return;
      }
      this.save(event.data);
    }

    if (
      event.data.type === AppConnectMessageType.UNAUTHORIZED &&
      event.origin === environment.ADMIN_HUB_URL
    ) {
      window.location.href = environment.ADMIN_HUB_URL;
    }

    if (
      event.data.type === AppConnectMessageType.SUCCESSFUL &&
      event.origin === environment.ADMIN_HUB_URL
    ) {
      localStorage.clear()
      const adminHubUrl = `${environment.ADMIN_HUB_URL}/login`;
      window.location.href = adminHubUrl;
    }
  }

  save(data: any): void {
    localStorage.setItem(environment.TOKEN, JSON.stringify(data.token));
    localStorage.setItem(environment.USER_PROFILE, data.profile);
    localStorage.setItem(environment.AUTH_STATUS, JSON.stringify({ isLoggedIn: true }));
    if (window.location.pathname === '/') {
      this.router.navigate(['/user-assignment']);
    }
  }

  connectAdminHub() {
    const ifr = document.createElement('iframe');
    ifr.classList.add('hidden');
    document.body.appendChild(ifr);
    ifr.src = this.adminHubConnectUrl;
    const connect = () => {
      const result = {
        type: AppConnectMessageType.CONNECT
      };
      ifr.contentWindow?.postMessage(result, { targetOrigin: environment.ADMIN_HUB_URL });
    };
    ifr.onload = connect;
  }
}
