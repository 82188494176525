export const ModerateRole = {
  SUPER_ADMIN: {
    name: 'Super Admin',
    code: 'SUPER_ADMIN'
  },
  ADMIN: {
    name: 'Admin',
    code: 'ADMIN'
  },
  MANAGER: {
    name: 'Manager',
    code: 'MANAGER'
  },
  LEADER: {
    name: 'Leader',
    code: 'LEADER'
  },
  MEMBER: {
    name: 'Member',
    code: 'MEMBER'
  },
  COLLABORATORS: {
    name: 'Collaborators',
    code: 'COLLABORATORS'
  },
}

export const ManagementRoles = [
  {
    name: ModerateRole.SUPER_ADMIN.name,
    code: ModerateRole.SUPER_ADMIN.code,
    level: 30
  },
  {
    name: ModerateRole.ADMIN.name,
    code: ModerateRole.ADMIN.code,
    level: 25
  },
  {
    name: ModerateRole.MANAGER.name,
    code: ModerateRole.MANAGER.code,
    level: 20
  },
  {
    name: ModerateRole.LEADER.name,
    code: ModerateRole.LEADER.code,
    level: 15
  },
  {
    name: ModerateRole.MEMBER.name,
    code: ModerateRole.MEMBER.code,
    level: 10
  },
  {
    name: ModerateRole.COLLABORATORS.name,
    code: ModerateRole.COLLABORATORS.code,
    level: 5
  }
];

export const TOAST_MESSAGE_LIFETIME_DURATIONS = {
  short: 2000,
  medium: 5000,
  long: 7000
};

export const ModuleType = {
  all: 'ALL'
}

export const TOAST_MESSAGE_SEVERITY_LEVELS = {
  warn: 'warn',
  info: 'info',
  success: 'success',
  error: 'error'
};

export const ModerateType = {
  add: 'add',
  replace: 'replace',
  delete: 'delete',
  edit: 'edit'
}

export const DefaultPaginator = {
  numberRowPerPage: 10,
  rowsPerPageOptions: [10, 20, 30]
};

export const StatusType = {
  active: 'ACTIVE',
  inactive: 'INACTIVE',
  blocked: 'BLOCKED',
  deleted: 'DELETED',
  scheduleDeleted: 'SCHEDULED_DELETED',
  warn: 'WARNING'
}

export const UserOptions = {
  inactive: {
    id: 'INACTIVE',
    label: 'USER_MANAGEMENT.STATUS_TYPE.INACTIVE',
    icon: 'sctr-icon-check-circle'
  },
  active: {
    id: 'ACTIVE',
    icon: 'sctr-icon-check-circle',
    label: 'USER_MANAGEMENT.STATUS_TYPE.ACTIVE'
  },
  block: {
    id: 'BLOCKED',
    icon: 'sctr-icon-lock-03',
    label: 'USER_MANAGEMENT.STATUS_TYPE.BLOCKED'
  },
  delete: {
    id: 'DELETED',
    label: 'USER_MANAGEMENT.STATUS_TYPE.DELETED',
    icon: 'sctr-icon-trash-02'
  },
  scheduleDeleted: {
    id: 'SCHEDULED_DELETED',
    label: 'USER_MANAGEMENT.STATUS_TYPE.SCHEDULED_DELETED',
    icon: ''
  },
  assignUser: {
    id: 'ASSIGN_USER',
    label: 'USER_MANAGEMENT.STATUS_TYPE.ASSIGN_USER',
    icon: 'sctr-icon-user-down-01'
  },
  replaceUser: {
    id: 'REPLACE_USER',
    label: 'USER_MANAGEMENT.STATUS_TYPE.REPLACE_USER',
    icon: 'sctr-icon-edit-03'
  },
  removeUser: {
    id: 'REMOVE_USER',
    label: 'USER_MANAGEMENT.STATUS_TYPE.REMOVE_USER',
    icon: 'sctr-icon-user-x-01',
    iconClass: 'text-palette-red-600',
    styleClass: 'remove-user-color'
  },
  warn: {
    id: 'WARNING',
    label: 'USER_MANAGEMENT.STATUS_TYPE.WARNED',
    icon: 'sctr-icon-info-circle'
  },
  editRole: {
    id: 'EDIT_ROLE',
    label: 'USER_MANAGEMENT.EDIT_ROLE',
    icon: 'sctr-icon-user-edit'
  },
};

export const UserStatus = [
  {label: UserOptions.active.label, id: StatusType.active},
  {label: UserOptions.block.label, id: StatusType.blocked},
  {label: UserOptions.inactive.label, id: StatusType.inactive},
  {label: UserOptions.delete.label, id: StatusType.deleted},
  {label: UserOptions.scheduleDeleted.label, id: StatusType.scheduleDeleted},
  {label: UserOptions.warn.label, id: StatusType.warn}
]