import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CountryService } from '@app/core/services/country.service';
import { ToastService } from '@app/core/services/toast.service';
import { UserService } from '@app/core/services/user.service';
import { RegionalActions } from '@app/modules/main/states/regional/regional.actions';
import { TOAST_MESSAGE_SEVERITY_LEVELS } from '@app/shared/const';
import { Store } from '@ngrx/store';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'app-country-list',
  templateUrl: './country-list.component.html',
  styleUrls: ['./country-list.component.scss']
})
export class CountryListComponent implements OnChanges {
  @Input() isModerator = false;
  @Input() countriesList: any[];
  @Input() userInfo: any;
  @Input() amount = 0;
  @Input() isLoading = false;
  @Output() selectLocation = new EventEmitter();
  @Output() amountChange = new EventEmitter();
  selectedCountry: any;
  countriesTree: TreeNode[] = [];
  selectedPlace: TreeNode;
  regionList: any = [];
  selectedRegion: any;
  

  constructor(
    private countryService: CountryService,
    private userService: UserService,
    private toastService: ToastService,
    private store: Store
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['countriesList'] && changes['countriesList'].currentValue && changes['countriesList'].currentValue.length) {
      this.selectedCountry = changes['countriesList'].currentValue[0];
      this.store.dispatch(RegionalActions.onGetRegionalStatistics({region: changes['countriesList'].currentValue[0]?.country_code}))
      this.getRegion(this.selectedCountry);
    }
    if (changes['amount'] && changes['amount'].currentValue && this.selectedPlace) {
      const totalUser = this.selectedPlace.label?.split('_')[1] || '0';
      this.selectedPlace.label = this.selectedPlace.label?.replace(totalUser, Number(totalUser) + changes['amount'].currentValue);
      if (this.selectedPlace.parent) {
        const index = this.countriesTree.findIndex(item => item.key === this.selectedPlace.parent?.key);
        const total = this.countriesTree[index].label?.split('_')[1] || '0';
        const newTotal = changes['amount'].currentValue > changes['amount'].previousValue ? Number(total) + 1 : Number(total) - 1;
        this.countriesTree[index].label = this.countriesTree[index].label?.replace(total, newTotal.toString());
      }
      this.amountChange.emit(null)
    }
  }


  updateRegion(event: any) {
    this.selectLocation.emit({
      country_code: event.value.country_code,
      country_name: event.value.country,
      total_user: this.isModerator ? event.value.number_moderator_of_countries : event.value.number_users_of_country
    });
    this.getRegion(event.value);
    this.store.dispatch(RegionalActions.onGetRegionalStatistics({region: event.value.country_code}))
  }

  getRegion(countryData: any) {
    this.isLoading = true;
    if (!this.isModerator) {
      this.countryService.getRegion(countryData.country_code).subscribe({
        next: (res: any[]) => {
          if (res) {
            this.getRegionTree(countryData, res);
            this.isLoading = false;
          }
        },
        error: (error: { error: string; }) => {
          this.isLoading = false;
          this.toastService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.error, error.error);
        }
      });
    } else {
      this.userService.getModeratedRegion(this.userInfo.id, countryData.country_code).subscribe(
        (res: any[]) => {
          if (res) {
            this.getRegionTree(countryData, res);
            this.isLoading = false;
          }
        },
        (error: { error: string; }) => {
          this.isLoading = false;
          this.toastService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.error, error.error);
        });
    }
  }

  getRegionTree(countryData: any, regionList: any[]) {
    const countryItem: TreeNode = {
      key: `${countryData.country_code} _ ${countryData.country}`,
      label: `${countryData.country}_${this.isModerator ? countryData.number_moderator_of_countries : countryData.number_users_of_country}`,
      data: countryData.country_code,
      leaf: countryData.country_code === 'OTHERS' ? true : false,
      children: [],
      expanded: true,
      partialSelected: true
    };
    this.countriesTree = [countryItem];
    this.selectedPlace = countryItem;
    regionList.forEach((el: { region: string; number_moderator_of_region?: boolean; number_users_of_region?: boolean}, i: any) => {
      const regionItem: TreeNode = {
        key: `${countryData.country_code} _ ${el.region}`,
        label: `${el.region}_${this.isModerator ? el.number_moderator_of_region : el.number_users_of_region}`,
        data: el.region,
        leaf: true,
        partialSelected: true
      };
      this.countriesTree[0].children?.push(regionItem);
    });
  }

  nodeSelect(event: any) {
    const node = event.node;
    const totalUser = node.label.split('_')[1] || 0;
    if (node && node.children) {
      node.expanded = !node.expanded;
      this.selectLocation.emit({
        country_code: node.data,
        country_name: this.getRegionLabel(node.label, true),
        total_user: Number(totalUser)
      });
    } else {
      this.selectLocation.emit({
        country_code: node.parent.data,
        country_name: this.getRegionLabel(node.parent.label, true),
        region: node.data,
        total_user: Number(totalUser)
      });
    }
  }

  getRegionLabel(label: string, isText: boolean) {
    const labelArray = label.split('_');
    if (isText) {
      return labelArray[0];
    } else {
      return Number(labelArray[1]) ? labelArray[1] : 0;
    }
  }
}
