<div
  class="card table-user-management w-full bg-palette-base-white rounded-md overflow-hidden" #tableView
>
  <p-table
    *ngIf="!isUseTreeTable"
    [styleClass]="'p-table'"
    [value]="isLoading ? loadingData : data"
    [paginator]="false"
    [tableStyle]="{ 'min-width': '1000px' }"
    [showCurrentPageReport]="false"
    [scrollHeight]="scrollHeight"
    [scrollable]="true"
    responsiveLayout="scroll"
    #table
  >
    <ng-template pTemplate="caption">
      <ng-container
        *ngTemplateOutlet="captionTable; context: { tableEl: table }"
      ></ng-container>
    </ng-template>
    <ng-template pTemplate="header">
      <ng-container *ngTemplateOutlet="headerTable"></ng-container>
    </ng-template>
    <ng-template pTemplate="body" let-user>
      <tr class="custom-row text-palette-gray-600">
        <td class="w-[18%] max-w-[450px]">
          <div *ngIf="!isLoading" class="flex flex-row text-ellipsis items-center gap-2">
            <app-avatar
              [avatarSize]="36"
              [userInfo]="user"
              [isShowFrame]="false"
              [readOnly]="true"
            />
            <div class="flex flex-col text-ellipsis overflow-hidden">
              <span
                class="vertical-align-middle ml-1 font-semibold text-ellipsis overflow-hidden whitespace-nowrap text-palette-gray-900"
                (click)="navigate(user.id)"
                >{{ user.first_name }} {{ user.last_name }}</span
              >
              <span
                class="vertical-align-middle ml-1 text-ellipsis overflow-hidden"
                >{{ user.username_id }}</span
              >
            </div>
          </div>
          <div *ngIf="isLoading" class="flex items-center gap-2 py-0.5">
            <p-skeleton shape="circle" size="32px" />
            <p-skeleton width="6rem" height="20px" borderRadius="20px"></p-skeleton>
          </div>
        </td>
        <ng-container
          *ngTemplateOutlet="
            bodyTable;
            context: { rowData: user, tableEl: table, rowNode: null }
          "
        ></ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
          <td [attr.colspan]="5">{{'COMMON.NOT_DATA_FOUND' | translate}}</td>
      </tr>
    </ng-template>
  </p-table>

  <p-treeTable
    *ngIf="isUseTreeTable"
    [value]="isLoading ? loadingData: data"
    [scrollable]="true"
    [tableStyle]="{ 'min-width': '1000px' }"
    [scrollHeight]="scrollHeight"
    responsiveLayout="scroll"
    (onNodeExpand)="onNodeExpand($event)"
    [lazy]="true"
    #treeTable
  >
    <ng-template pTemplate="caption">
      <ng-container
        *ngTemplateOutlet="captionTable; context: { tableEl: treeTable }"
      ></ng-container>
    </ng-template>
    <ng-template pTemplate="header">
      <ng-container *ngTemplateOutlet="headerTable"></ng-container>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
      <tr [ttRow]="rowNode">
        <td class="w-[18%]">
          <div *ngIf="!isLoading" class="flex flex-row text-ellipsis items-center">
            <div
              class="flex"
              [ngClass]="'items-center toggleCenter'"
            >
              <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
              <app-avatar
                [avatarSize]="32"
                [userInfo]="rowData"
                [isShowFrame]="false"
                [readOnly]="true"
              />
            </div>
            <div
              class="flex flex-col text-ellipsis overflow-hidden justify-center"
            >
              <span
                class="vertical-align-middle ml-1 font-semibold text-ellipsis overflow-hidden whitespace-nowrap text-palette-gray-900 h-max"
                (click)="navigate(rowData.id)"
                [pTooltip]="rowData.full_name" tooltipPosition="top"
                >{{ rowData.full_name }}</span
              >
              <span
                class="vertical-align-middle ml-1 text-ellipsis overflow-hidden"
                >{{ rowData.username }}</span
              >
            </div>
          </div>
          <div *ngIf="isLoading" class="flex items-center gap-2 py-0.5">
            <p-skeleton shape="circle" size="32px" />
            <p-skeleton width="6rem" height="20px" borderRadius="20px"></p-skeleton>
          </div>
        </td>
        <ng-container
          *ngTemplateOutlet="
            bodyTable;
            context: { rowData: rowData, tableEl: treeTable, rowNode: rowNode }
          "
        ></ng-container>
      </tr>
      <tr *ngIf="rowNode.node.isShowBtn">
        <td [attr.colspan]="6">
          <p-button (onClick)="loadMoreChildrenData(rowNode)" [styleClass]="'p-button-secondary px-4 py-2 h-10'" [ngClass]="{'ml-14': rowNode.node.childLevel === 1, 'ml-[70px]': rowNode.node.childLevel === 2, 'ml-[84px]': rowNode.node.childLevel === 3}" [disabled]="isLoadingChild"
          [icon]="isLoadingChild ? 'pi-spin pi sctr-icon-loading-02 mr-2' : ''">
            {{'USER_MANAGEMENT.LOAD_MORE' | translate}}
          </p-button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
          <td [attr.colspan]="6">{{'COMMON.NOT_DATA_FOUND' | translate}}</td>
      </tr>
    </ng-template>
  </p-treeTable>

  <div class="flex items-center justify-between gap-2">
    <div
      class="custom-show-page my-4 mx-1 lg:m-4 flex items-center text-palette-gray-500"
    >
      <span class="text-palette-gray-500">{{
        "USER_MANAGEMENT.ROWS_PER_PAGE" | translate
      }}</span>
      <p-dropdown
        [(ngModel)]="pageSize"
        [appendTo]="tableView"
        [styleClass]="'h-9 w-[68px] mx-1 RowsPerPage'"
        [options]="selectedRowsPerPage"
        (onChange)="onRowsPerPageChange($event)"
      ></p-dropdown>
      <span class="text-palette-gray-500">{{
        handleGeneratePaginatorCurrent()
      }}</span>
    </div>
    <div class="custom-paginator">
      <p-paginator
        [first]="first"
        [rows]="rows"
        [totalRecords]="totalRecords"
        (onPageChange)="onPageChange($event)"
      ></p-paginator>
    </div>
  </div>
</div>

<!-- Template caption -->
<ng-template #captionTable let-tableEl="tableEl">
  <div class="flex items-center justify-between pl-6 pr-4 py-4 gap-4">
    <div class="text-lg leading-7 font-semibold text-palette-gray-900">
      {{ tableName }}
    </div>
    <div class="flex gap-3">
      <!-- Search -->
      <div class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          type="text"
          class="gap-2 w-80 rounded-lg py-[10px] text-base"
          pInputText
          [(ngModel)]="keyword"
          (input)="onSearchTextChange($event)"
          (keyup)="searchData()"
          [placeholder]="'COMMON.SEARCH' | translate"
        />
      </div>
      <!-- Filter -->
      <div>
        <p-button
          [styleClass]="'p-button-secondary px-4 py-2.5 gap-2 h-11'"
          (onClick)="visibleFilter = !visibleFilter"
        >
          <span class="font-semibold text-palette-gray-800">{{
            "USER_MANAGEMENT.FILTER" | translate
          }}</span>
          <i class="sctr-icon-filter-lines text-xl"></i>
        </p-button>
        <p-overlay
          (onHide)="handleOverlayHide()"
          [appendTo]="tableEl"
          [(visible)]="visibleFilter"
        >
          <p-card>
            <ng-template pTemplate="header">
              <div class="flex items-center justify-between gap-6 p-4 pb-2">
                <h1
                  class="text-lg font-semibold leading-7 text-palette-gray-800"
                >
                  {{ "USER_MANAGEMENT.FILTER" | translate }}
                </h1>
                <p-button
                  icon="pi pi-times"
                  [styleClass]="
                    'p-button-rounded p-button-secondary p-2 border-none'
                  "
                  (onClick)="closeDialog()"
                ></p-button>
              </div>
            </ng-template>
            <ng-template pTemplate="content">
              <div
                class="flex gap-4 py-0 px-4"
                
                [formGroup]="filterGroup"
              >
                <div class="flex-auto" *ngIf="isUseTreeTable">
                  <label
                    for="role"
                    class="mb-2 block font-medium text-palette-gray-700"
                    >{{ "USER_MANAGEMENT.ROLE" | translate }}</label
                  >
                  <p-multiSelect
                    [appendTo]="filterRolesEl"
                    [filter]="false"
                    [styleClass]="'w-60'"
                    id="role"
                    [options]="filteredRoles"
                    optionLabel="name"
                    optionValue="code"
                    formControlName="selectedRoles"
                    [placeholder]="
                      'USER_MANAGEMENT.ROLE_PLACEHOLDER' | translate
                    "
                  ></p-multiSelect>
                  <div class="filter-overlay" #filterRolesEl></div>
                </div>
                <div class="flex-auto">
                  <label
                    for="status"
                    class="mb-2 block font-medium text-palette-gray-700"
                    >{{ "USER_MANAGEMENT.STATUS" | translate }}</label
                  >
                  <p-multiSelect
                    [appendTo]="filterStatusesEl"
                    [filter]="false"
                    [styleClass]="'w-60'"
                    id="status"
                    [options]="filteredStatuses"
                    optionLabel="label"
                    optionValue="id"
                    formControlName="selectedStatuses"
                    [placeholder]="
                      'USER_MANAGEMENT.STATUS_PLACEHOLDER' | translate
                    "
                  ></p-multiSelect>
                  <div class="filter-overlay" #filterStatusesEl></div>
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="footer">
              <div class="flex justify-end gap-3 p-4 pt-8">
                <p-button
                  [styleClass]="'p-button-secondary'"
                  label="Cancel"
                  (onClick)="closeDialog()"
                ></p-button>
                <p-button
                  (onClick)="applyFilter()"
                  [label]="'COMMON.APPLY' | translate"
                ></p-button>
              </div>
            </ng-template>
          </p-card>
        </p-overlay>
      </div>
      <!-- Add user -->
      <p-button
        *ngIf="isUseTreeTable"
        [styleClass]="
          'px-3 py-2.5 gap-2 h-11 bg-palette-blue-600 text-palette-base-white'
        "
        (onClick)="addModerator()"
      >
        <i class="sctr-icon-plus text-xl"></i>
        <span>{{ "USER_MANAGEMENT.ADD_USER" | translate }}</span>
      </p-button>
    </div>
  </div>
</ng-template>

<!-- Template header -->
<ng-template #headerTable>
  <tr>
    <th class="font-medium w-[18%] text-sm/[18px] text-palette-gray-600">
      {{ "USER_MANAGEMENT.NAME" | translate }}
    </th>
    <th class="font-medium w-[18%] text-sm/[18px] text-palette-gray-600">
      {{ "USER_MANAGEMENT.EMAIL_ADDRESS" | translate }}
    </th>
    <th class="font-medium w-[18%] text-sm/[18px] text-palette-gray-600">
      {{ "USER_MANAGEMENT.ROLE" | translate }}
    </th>
    <th class="font-medium w-[18%] text-sm/[18px] text-palette-gray-600">
      {{
        (isUseTreeTable
          ? "USER_MANAGEMENT.ADDED_DATE"
          : "USER_MANAGEMENT.REGISTER_DATE"
        ) | translate
      }}
    </th>
    <th class="font-medium w-[18%] text-sm/[18px] text-palette-gray-600">
      {{ "USER_MANAGEMENT.STATUS" | translate }}
    </th>
    <th *ngIf="isUseTreeTable" class="font-medium w-[10%] pl-0 text-sm/[18px] text-palette-gray-600">
      {{ "USER_MANAGEMENT.ACTIONS" | translate }}
    </th>
  </tr>
</ng-template>

<!-- Template body -->
<ng-template #bodyTable let-user="rowData" let-rowNode="rowNode" let-tableEl="tableEl">
  <ng-container *ngIf="isLoading">
    <td
      *ngFor="let item of [].constructor(isUseTreeTable ? 5 : 4); let i = index"
      class="py-0.5"
      [ngClass]="{ 'w-[18%]': i !== (isUseTreeTable ? 4 : 3), 'w-[10%]': i === (isUseTreeTable ? 4 : 3) }">
      <div
        class="flex items-center">
        <p-skeleton width="6rem" height="20px" borderRadius="20px"></p-skeleton>
      </div>
    </td>
  </ng-container>
  <ng-container *ngIf="!isLoading">
    <td class="text-ellipsis overflow-hidden w-[18%]"><span class="h-max" [pTooltip]="user.email" tooltipPosition="top">{{ user.email }}</span></td>
    <td class="w-[18%]">
      {{ isUseTreeTable ? user.role : ("USER_MANAGEMENT.USER" | translate) }}
    </td>
    <td class="w-[18%]">
      {{ isUseTreeTable ? user.added_date : user.register_date }}
    </td>
    <td class="w-[18%]">
      <ng-container
        *ngIf="
          user.status === statusType.inactive || user.status === null;
          else ifActive
        "
      >
        <p-tag
          [rounded]="true"
          [styleClass]="'text-palette-red-700 bg-palette-red-50'"
        >
          <div class="items-center flex gap-1">
            <i class="pi pi-circle-fill text-[7px]"></i>
            <span class="font-medium leading-[18px]">{{
              userOptions.inactive.label | translate
            }}</span>
          </div>
        </p-tag>
      </ng-container>

      <ng-template #ifActive>
        <ng-container
          *ngIf="user.status === statusType.active; else ifBlocked"
        >
          <p-tag
            [rounded]="true"
            [styleClass]="'text-palette-emerald-700 bg-palette-emerald-50'"
          >
            <div class="items-center flex gap-1">
              <i class="pi pi-circle-fill text-[7px]"></i>
              <span class="font-medium leading-[18px]">{{
                userOptions.active.label | translate
              }}</span>
            </div>
          </p-tag>
        </ng-container>

        <ng-template #ifBlocked>
          <ng-container
            *ngIf="user.status === statusType.blocked; else ifDeleted"
          >
            <p-tag
              [rounded]="true"
              [styleClass]="'text-palette-gray-700 bg-palette-gray-200'"
            >
              <div class="items-center flex gap-1">
                <i class="pi pi-circle-fill text-[7px]"></i>
                <span class="font-medium leading-[18px]">{{
                  userOptions.block.label | translate
                }}</span>
              </div>
            </p-tag>
          </ng-container>
        </ng-template>

        <ng-template #ifDeleted>
          <ng-container *ngIf="user.status === statusType.deleted; else ifScheduleDeleted">
            <p-tag [rounded]="true" [styleClass]="'text-palette-gray-700 bg-palette-gray-200'">
              <div class="items-center flex gap-1">
                <i class="pi pi-circle-fill text-[7px]"></i>
                <span class="font-medium leading-[18px]">{{
                  userOptions.delete.label | translate
                }}</span>
              </div>
            </p-tag>
          </ng-container>
        </ng-template>

        <ng-template #ifScheduleDeleted>
          <ng-container *ngIf="user.status === statusType.scheduleDeleted; else ifWarned">
            <p-tag [rounded]="true" [styleClass]="'text-palette-gray-700 bg-palette-gray-200'">
              <div class="items-center flex gap-1">
                <i class="pi pi-circle-fill text-[7px]"></i>
                <span class="font-medium leading-[18px]">{{
                  userOptions.scheduleDeleted.label | translate
                }}</span>
              </div>
            </p-tag>
          </ng-container>
        </ng-template>

        <ng-template #ifWarned>
          <ng-container *ngIf="user.status === statusType.warn">
            <p-tag [rounded]="true" [styleClass]="'text-palette-red-600 bg-palette-red-100'">
              <div class="items-center flex gap-1">
                <i class="pi pi-circle-fill text-[7px]"></i>
                <span class="font-medium leading-[18px]">{{
                  userOptions.warn.label | translate
                }}</span>
              </div>
            </p-tag>
          </ng-container>
        </ng-template>  
      </ng-template>
    </td>
    <td class="w-[10%]" *ngIf="isUseTreeTable">
      <!-- <p-button
        *ngIf="isShowView"
        (click)="navigate(user.id)"
        styleClass="text-xs flex px-3 py-1 items-center self-stretch bg-palette-base-white border-none text-palette-blue-700 h-5"
      >
        {{ "USER_MANAGEMENT.VIEW" | translate }}
      </p-button> -->
      <div class="card justify-content-center flex">
        <p-menu
          #menu
          [styleClass]="'font-medium text-palette-gray-700'"
          [model]="userActions"
          [popup]="true"
          [appendTo]="tableEl"
          (onShow)="activateUser(rowNode)"
        ></p-menu>
        <p-button
          [styleClass]="
            'p-button-secondary bg-palette-base-white border-none justify-start'
          "
          (onClick)="menu.toggle($event)"
          icon="pi pi-ellipsis-v"
        ></p-button>
      </div>
    </td>
  </ng-container>
</ng-template>

<!-- Popups -->
<app-add-user-popup
  class="z-10"
  *ngIf="addVisible"
  [(addVisible)]="addVisible"
  [location]="location"
  [module]="module"
  [userRole]="assignedUserRole"
  [userId]="assignedUserId"
  [assignedUser]="assignedUser"
  [editUser]="selectedRowData ? selectedRowData.node.data : null"
  [type]="moderateType"
  (addModerators)="updateModeratorList($event, moderateType)"
  (replaceModerators)="updateModeratorList($event, moderateType)"
  (editModerators)="updateModeratorList($event, moderateType)"
/>

<p-confirmDialog
  id="dialog"
  [visible]="isShowDeleteConfirm"
  [closable]="false"
  message="{{ 'USER_MANAGEMENT.DELETE_WARNING' | translate: { full_name: this.selectedRowData?.node.data.full_name || ''} }}"
  header="{{ 'USER_MANAGEMENT.DELETE_MODERATOR' | translate }}">
  <ng-template pTemplate="footer">
    <button
      pButton
      label="{{ 'COMMON.CANCEL' | translate }}"
      (click)="deleteModerator(false)"
      class="text-palette-base-black bg-palette-base-white border-palette-gray-300 hover:bg-palette-gray-200"></button>
    <button
      pButton
      label="{{ 'COMMON.REMOVE' | translate }}"
      (click)="deleteModerator(true)"
      [disabled]="isLoadingDeleteUser"
      [icon]="isLoadingDeleteUser ? 'pi-spin pi sctr-icon-loading-02 mr-2' : ''"
      class="text-palette-base-white bg-palette-red-600 border-palette-red-600"></button>
  </ng-template>
</p-confirmDialog>