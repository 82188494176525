import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { PasswordModule } from 'primeng/password';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { AvatarComponent } from './components/avatar/avatar.component';
import { ModuleListComponent } from './components/module-list/module-list.component';
import { BadgeModule } from 'primeng/badge';
import { OutsideClickDirective } from './directives/outside-click.directive';
import { ScrollDirective } from './directives/scroll.directive';
import { LoadingComponent } from './components/loading/loading.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { BlockUIModule } from 'primeng/blockui';
import { CountryListComponent } from './components/country-list/country-list.component';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';
import { ZoomableWorldMapDirective } from './directives/world-map.directive';
import { UserTableComponent } from './components/user-table/user-table.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AddUserPopupComponent } from './components/add-user-popup/add-user-popup.component';
import { SkeletonModule } from 'primeng/skeleton';

const sharedComponents = [
  AvatarComponent,
  ModuleListComponent,
  LoadingComponent,
  CountryListComponent,
  ToastMessageComponent,
  ZoomableWorldMapDirective,
  UserTableComponent,
  AddUserPopupComponent
];
const sharedDirectives = [OutsideClickDirective, ScrollDirective];
const commonModules = [CommonModule, ReactiveFormsModule, FormsModule, TranslateModule];
const primeNgModules = [
  ButtonModule,
  TableModule,
  MenuModule,
  DropdownModule,
  CardModule,
  InputTextModule,
  CheckboxModule,
  RadioButtonModule,
  DropdownModule,
  InputTextareaModule,
  AvatarModule,
  TagModule,
  DividerModule,
  MultiSelectModule,
  PaginatorModule,
  ToastModule,
  DialogModule,
  PasswordModule,
  ListboxModule,
  AutoCompleteModule,
  SidebarModule,
  TreeTableModule,
  TreeModule,
  BadgeModule,
  ProgressSpinnerModule,
  BlockUIModule,
  ConfirmDialogModule,
  SkeletonModule
];

@NgModule({
  declarations: [sharedComponents, sharedDirectives],
  imports: [commonModules, primeNgModules],
  exports: [commonModules, primeNgModules, sharedComponents, sharedDirectives]
})
export class SharedModule { }
