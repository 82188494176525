import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '@app/lib/api/api-client';
import { CityModel, CountryModel, RegionModel, RegionalStatisticsModel } from '@app/lib/api/country-region/api.country.model';
import { environment } from '@env/environment.prod';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  private api: ApiClient;
  constructor(private http: HttpClient) {
    this.api = new ApiClient(this.http, { responseTimeout: environment.API_TIMEOUT });
  }

  getCountry(pageNum: number, pageSize: number): Observable<CountryModel[]> {
    return this.api.country.getCountry(pageNum, pageSize);
  }

  getRegion(country_code: string): Observable<RegionModel[]> {
    return this.api.country.getRegion(country_code);
  }

  getCities(region: string): Observable<CityModel[]> {
    return this.api.country.getCities(region);
  }

  getRegionalStatistics(region: string): Observable<RegionalStatisticsModel> {
    return this.api.country.getRegionalStatistics(region);
  }
}
