import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[scroll]'
})
export class ScrollDirective {
  @Output() scrolledToBottom: EventEmitter<void> = new EventEmitter<void>();

  constructor(private el: ElementRef) {}

  @HostListener('scroll', ['$event'])
  onScroll(event: Event): void {
    const element = event.target as HTMLElement;
    const tolerance = 1;
    if (Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) <= tolerance) {
      this.scrolledToBottom.emit();
    }
  }
}
