import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { ApiClientConfig } from '../api-client.config';
import {
  AddModeratorRequest,
  AddModeratorResponse,
  ModeratedCity,
  ModeratedCityResponse,
  ModeratedCountry,
  ModeratedCountryResponse,
  ModeratedRegion,
  ModeratedRegionResponse,
  Moderator,
  ModeratorListRequest,
  ModeratorResponse,
  ModeratorRole,
  ModeratorRoleRequest,
  ModeratorRoleResponse,
  ModuleList,
  ModuleListRequest,
  ModuleListResponse,
  UserInfoResponse,
  UserManagementResponse
} from './api.user.model';
import { ResponseObject } from '@app/shared/models/response-object';
import { EditModeratorRequest } from '@app/shared/models/users-management';

export class UserApi {
  private apiUrl: string = environment.API_URL + '/user';

  constructor(
    public readonly http: HttpClient,
    public config: ApiClientConfig
  ) { }

  getUserById(id: string): Observable<UserInfoResponse> {
    return this.http.get<any>(this.apiUrl + '/users/' + id).pipe(
      timeout(this.config.responseTimeout),
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getUserList(
    pageNum: number,
    pageSize: number,
    country_code: string,
    region: string,
    city: string,
    textSearch: string,
    status: string,
    roles: string
  ): Observable<UserManagementResponse> {
    let url = `/admin/search?pageNum=${pageNum}&pageSize=${pageSize}&countryCode=${country_code}`;
    if (region) url = url + `&region=${region}`;
    if (city) url = url + `&city=${city}`;
    
    if (textSearch) url = url + `&textSearch=${textSearch}`;
    if (status) url = url + `&status=${status}`;
    if (roles) url = url + `&roles=${roles}`;
    return this.http.get<UserManagementResponse>(this.apiUrl + url).pipe(
      timeout(this.config.responseTimeout),
      map((res: UserManagementResponse) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getCountries(): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}/booking-generic/country/list`).pipe(
      timeout(this.config.responseTimeout),
      map((res: any) => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getModerateRole(body: ModeratorRoleRequest): Observable<string> {
    return this.http.post<any>(`${this.apiUrl}/admin/role`, body).pipe(
      timeout(this.config.responseTimeout),
      map((res: any) => {
        return res.data.role;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  addModerator(body: AddModeratorRequest): Observable<ResponseObject> {
    return this.http.post<ResponseObject>(`${this.apiUrl}/admin/moderator`, body).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getModerators(
    pageNum: number,
    pageSize: number,
    param: ModeratorListRequest,
    textSearch: string,
    status: string,
    roles: string
  ): Observable<ModeratorResponse> {
    let url = `${this.apiUrl}/admin/moderators?pageNum=${pageNum}&pageSize=${pageSize}&nodeId=${param.node_id}&countryCode=${param.country_code}&type=${param.type}&typeModerator=${param.type_moderator}`;
    if (param.region) url = url + `&region=${param.region}`;
    if (param.city) url = url + `&city=${param.city}`;
    if (textSearch) url = url + `&textSearch=${textSearch}`;
    if (status) url = url + `&status=${status}`;
    if (roles) url = url + `&roles=${roles}`;
    return this.http.get<ModeratorResponse>(url).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getModuleList(body: ModuleListRequest): Observable<ModuleList> {
    return this.http.post<ModuleListResponse>(`${this.apiUrl}/admin/modules/item`, body).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getModeratorRole(): Observable<ModeratorRole[]> {
    return this.http.get<ModeratorRoleResponse>(`${this.apiUrl}/admin/roles/module`).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res.data;
      }),
      catchError(error => {
        window.location.href = `${environment.ADMIN_HUB_URL}`;
        throw error.error;
      })
    );
  }

  getModeratedCountries(userId: string): Observable<ModeratedCountry[]> {
    return this.http.get<ModeratedCountryResponse>(`${this.apiUrl}/admin/countries/${userId}`).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getModeratedRegion(userId: string, country_code: string): Observable<ModeratedRegion[]> {
    return this.http.get<ModeratedRegionResponse>(`${this.apiUrl}/admin/regions/${userId}/${country_code}`).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getModeratedCity(userId: string, region: string): Observable<ModeratedCity[]> {
    return this.http.get<ModeratedCityResponse>(`${this.apiUrl}/admin/cities/${userId}/${region}`).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  searchModerators(
    pageNum: number,
    pageSize: number,
    param: ModeratorListRequest,
    textSearch: string,
    status: string,
    roles: string
  ): Observable<ModeratorResponse> {
    let url = `${this.apiUrl}/admin/search-moderators?pageNum=${pageNum}&pageSize=${pageSize}&nodeId=${param.node_id}&countryCode=${param.country_code}&type=${param.type}&typeModerator=${param.type_moderator}`;
    if (param.region) url = url + `&region=${param.region}`;
    if (param.city) url = url + `&city=${param.city}`;
    if (textSearch) url = url + `&textSearch=${textSearch}`;
    if (status) url = url + `&status=${status}`;
    if (roles) url = url + `&roles=${roles}`;
    return this.http.get<ModeratorResponse>(url).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  deleteModerator(body: AddModeratorRequest): Observable<ResponseObject> {
    return this.http.delete<ResponseObject>(`${this.apiUrl}/admin/moderator`, {
      headers: {
        'Content-Type': 'application/json'
      },
      body: body
    }).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  replaceModerator(body: AddModeratorRequest): Observable<ResponseObject> {
    return this.http.put<ResponseObject>(`${this.apiUrl}/admin/replace`, body).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  editRoleModerator(body: EditModeratorRequest): Observable<ResponseObject> {
    return this.http.put<ResponseObject>(`${this.apiUrl}/admin/moderator`, body).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getUsersExcludeModerator(
    pageNum: number,
    pageSize: number,
    currentModule: any,
    currentLocation: any,
    textSearch?: string
  ): Observable<ResponseObject> {
    let url = `/admin/search?pageNum=${pageNum}&pageSize=${pageSize}`;
    let root = `&root=${currentLocation.country_code}`;
    if (currentLocation.region) root = root + `>${currentLocation.region}`;
    if (currentLocation.city) root = root + `>${currentLocation.city}`;
    root = root + `>${currentModule.parent_module.value}>${currentModule.module.value}`;
    
    if (textSearch) url = url + `&textSearch=${textSearch}`;
    return this.http.get<ResponseObject>(this.apiUrl + url + root).pipe(
      timeout(this.config.responseTimeout),
      map((res: ResponseObject) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }
}
