import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[soctripOutsideClick]'
})
export class OutsideClickDirective {
  @Output()
  outsideClicked: EventEmitter<MouseEvent> = new EventEmitter();

  @HostListener('document:mousedown', ['$event'])
  onClick(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.outsideClicked.emit(event);
    }
  }

  constructor(private elementRef: ElementRef) {}
}
