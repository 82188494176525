import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UserService } from '@app/core/services/user.service';
import { ManagementRoles, ModerateType, TOAST_MESSAGE_SEVERITY_LEVELS } from '@app/shared/const';
import { FILE_TYPE_URL } from '@app/shared/models/post';
import { UserModel } from '@app/shared/models/user';
import { AddModeratorRequest, EditModeratorRequest, ManagementRoleModel } from '@app/shared/models/users-management';
import { AutoComplete } from 'primeng/autocomplete';
import { environment } from '@env/environment';
import { differenceWith, isEqual } from 'lodash';
import { ToastService } from '@app/core/services/toast.service';

@Component({
  selector: 'app-add-user-popup',
  templateUrl: './add-user-popup.component.html',
  styleUrls: ['./add-user-popup.component.scss']
})
export class AddUserPopupComponent implements OnInit {
  @Input() addVisible: boolean;
  @Input() location: any;
  @Input() module: any;
  @Input() userRole: string;
  @Input() userId: string;
  @Input() assignedUser = '';
  @Input() type: string;
  @Input() editUser: any ;
  @Output() addVisibleChange = new EventEmitter();
  @Output() addModerators = new EventEmitter();
  @Output() replaceModerators = new EventEmitter();
  @Output() editModerators = new EventEmitter();

  selectUsers: UserModel[] = [];
  selectedRole: string;
  userSuggestions: UserModel[] = [];
  rolesList: ManagementRoleModel[];
  inputArr: { key: string; options: UserModel[] }[] = [];
  moderateType = ModerateType;
  searchWaitTime: any;
  options: any = {
    showLoader: false,
    lazy: true,
    onLazyLoad: this.onLazyLoad.bind(this)
  };
  isLoadingUser = false;
  totalUserSuggestions = 0;
  keySearch = '';
  isLoading = false;
  isHide = true;
  @ViewChild('autoComplete') autoComplete: AutoComplete;

  constructor(
    private userService: UserService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    const currentLevel = ManagementRoles.find(role => role.code === this.userRole)?.level || 0;
    this.rolesList = ManagementRoles.filter(role => role.level < currentLevel);
    if (this.type === this.moderateType.replace || this.type === this.moderateType.edit) {
      this.selectedRole = this.userRole;
    }
  }

  selectItem(autoComplete: AutoComplete) {
    const filtered = this.filtered();
    this.userSuggestions = filtered;
    autoComplete.inputValue = '';
  }

  closeDialog() {
    this.addVisibleChange.emit(false);
  }

  add() {
    this.isLoading = true;
    // Always have country => default data = 1;
    let numberOfLocation = 1;
    if (this.location.region) numberOfLocation++;
    if (this.location.city) numberOfLocation++;
    const data: AddModeratorRequest = {
      country_code: this.location.country_code,
      region: this.location.region || '',
      city: this.location.city || '',
      type: this.module.parent_module.value,
      type_moderator: this.module.module.value,
      user_ids: this.type === this.moderateType.add ? this.selectUsers.map(el => el.id) : [(this.selectUsers as any).id],
      role: this.selectedRole.toString(),
      parent_id: this.userId,
      number_of_field_submitted: numberOfLocation
    };
    if (this.type === this.moderateType.add) {
      this.userService.addModerator(data).subscribe(res => {
        if (res.success) {
          this.addModerators.emit(res.data);
          this.isLoading = false;
          this.closeDialog();
        } else {
          this.onShowError(res.error.message);
          this.isLoading = false;
        }
      });
    } else if (this.type === this.moderateType.replace) {
      this.userService.replaceModerator(data).subscribe(res => {
        if (res.success) {
          this.replaceModerators.emit({data: res.data, previousUserId: this.userId});
          this.isLoading = false;
          this.closeDialog();
        } else {
          this.onShowError(res.error.message);
          this.isLoading = false;
        }
      });
    } else {
      const editData: EditModeratorRequest = {
        country_code: this.location.country_code,
        region: this.location.region || '',
        city: this.location.city || '',
        type: this.module.parent_module.value,
        type_moderator: this.module.module.value,
        user_id: this.editUser.user_id,
        role: this.selectedRole.toString()
      };
      this.userService.editRoleModerator(editData).subscribe(res => {
        if (res.success) {
          this.editModerators.emit({data: res.data, previousUserId: this.editUser.user_id});
          this.isLoading = false;
          this.closeDialog();
        } else {
          this.onShowError(res.error.message);
          this.isLoading = false;
        }
      });
    }
  }

  onShowError(errorMessage: string) {
    this.toastService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.error, errorMessage);
  }

  hide(e: any){
    this.isHide = true;
    this.totalUserSuggestions = 0;
    if (!e) {
      this.keySearch = '';
    }
  }

  searchInput(event: any) {
    if (this.searchWaitTime) {
      clearTimeout(this.searchWaitTime);
    }
    this.searchWaitTime = setTimeout(() => {
      this.getUserList(this.keySearch);
    }, 2000);
  }

  getUserList(search?: any) {    
    if (!search || search === '') return;
    this.userService.getUsersExcludeModerator(0, 10, this.module, this.location, search).subscribe((res: any) => {
      if (res.data) {
        this.userSuggestions = res.data.data; 
        this.totalUserSuggestions = res.data.totalElement;
      } else {
        this.totalUserSuggestions = 0;
      }
    });
  }

  validUrl(urlString: string, style = FILE_TYPE_URL.thumbnail) {
    return this.checkUrl(urlString, style);
  }

  checkUrl(urlString: string, style: string) {
    if (urlString && (urlString.includes('http:') || urlString.includes('https:'))) {
      return urlString;
    }
    if (urlString && urlString.includes('/storage/files/')) {
      return environment.API_URL + urlString;
    } else return `${environment.API_URL}/storage/files/${style}/${urlString}`;
  };

  filtered(): UserModel[] {
    return differenceWith(this.userSuggestions, this.selectUsers, isEqual);
  }

  isShowCheckUsers(id: string) {
    if (this.type === this.moderateType.add) {
      return this.selectUsers.findIndex(item => item.id === id) !== -1;
    } else {
      return (this.selectUsers as any).id === id;
    }
  }

  moduleText(data: any) {
    if (!data) return;
    return `${data.parent_module.name} - ${data.module.name}`;
  }

  locationText() {
    let text = this.location.country_name;
    if (this.location.region) text = `${text} - ${this.location.region}`;
    if (this.location.city) text = `${text} - ${this.location.city}`;
    return text;
  }

  onLazyLoad(event: any) {
    if (event.last >= this.userSuggestions.length && this.userSuggestions.length < this.totalUserSuggestions && !this.isLoadingUser) {
      this.isLoadingUser = true;
      this.userService.getUsersExcludeModerator(this.userSuggestions.length / 10, 10, this.module, this.location, this.keySearch).subscribe((res: any) => {
        if (res.data) {
          this.userSuggestions = this.userSuggestions.concat(res.data.data);
          this.isLoadingUser = false;
        }
      });
    }
  }
}
