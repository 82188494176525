import { CountryModel, RegionalStatisticsModel } from "@app/lib/api/country-region/api.country.model";
import { createActionGroup, props } from "@ngrx/store";

export const RegionalActions = createActionGroup({
  source: 'Regional',
  events: {
    'On Get Country List': props<{ pageNum: number, pageSize: number}>(),
    'On Get Regional Statistics': props<{region: string}>()
  }
});

export const RegionalApiActions = createActionGroup({
  source: 'Regional Api',
  events: {
    'On Get Country List Success': props<{ countryList: CountryModel[] }>(),
    'On Get Country List Fail': props<{ message: string }>(),
    'On Get Regional Statistics Success': props<{ regionalStatistics: RegionalStatisticsModel }>(),
    'On Get Regional Statistics Fail': props<{ message: string }>(),
  }
});