import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastService } from '@app/core/services/toast.service';
import { TranslationService } from '@app/core/services/translation.service';
import { UserService } from '@app/core/services/user.service';
import { DefaultPaginator, ManagementRoles, ModerateType, StatusType, UserOptions, UserStatus } from '@app/shared/const';
import { AddModeratorRequest, ModeratorListRequest, PageEvent, Role, Status } from '@app/shared/models/users-management';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { TreeTable } from 'primeng/treetable';

@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss']
})

export class UserTableComponent implements OnChanges {
  @Input() scrollHeight = '';
  @Input() isUseTreeTable = false;
  @Input() tableName = '';
  @Input() location: any;
  @Input() module: any;
  @Input() userRole: string;
  @Input() totalRecords = 0;
  @Input() data: any[] = [];
  @Input() userInfo: any;
  @Input() isLoading = false;
  @Output() totalUpdate = new EventEmitter<number>();
  @Output() navigateEvent = new EventEmitter();
  @Output() reloadDataEvent = new EventEmitter();
  @Output() updateModerator = new EventEmitter();

  userActions: MenuItem[] = [];
  first = 0;
  pageNum = 0;
  pageSize = DefaultPaginator.numberRowPerPage;
  rows = DefaultPaginator.numberRowPerPage;
  selectedRowsPerPage: number[] = DefaultPaginator.rowsPerPageOptions;
  filteredRoles: Role[] = [];
  filteredStatuses: Status[] = UserStatus;
  statusType = StatusType;
  userOptions = UserOptions;
  keyword = '';
  filterGroup: FormGroup;
  previousValueFilter: any;
  visibleFilter = false;
  addVisible = false;
  userManagement: any;
  assignedUserId: string;
  assignedUserRole: string;
  selectedRowData: any;
  timeOutData: any;
  isUpdateChildData = false;
  moderateType: string;
  isShowDeleteConfirm = false;
  assignedUser = '';
  loadingData = new Array(5).fill({});
  isLoadingNodeExpand = false; 
  isLoadingChild = false;
  isLoadingDeleteUser = false;
  @ViewChild('treeTable') treeTable: TreeTable;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private translate: TranslationService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.filterGroup = this.formBuilder.group({
      selectedRoles: [null],
      selectedStatuses: [null]
    });
    this.previousValueFilter = this.filterGroup?.value;
    this.initData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userRole'] && changes['userRole'].currentValue) {
      this.userRole = changes['userRole'].currentValue;
      this.getRolesList();
    }
    if (changes['location'] && changes['location'].currentValue){
      this.keyword = ''
    }
    if (changes['module'] && changes['module'].currentValue){
      this.pageSize = DefaultPaginator.numberRowPerPage;
    }
  }

  initData() {
    if (this.isUseTreeTable) {
      this.userActions = [
        {
          ...UserOptions.assignUser,
          command: () => { },
          visible: true
        },
        {
          ...UserOptions.editRole,
          command: () => { },
          visible: true
        },
        {
          ...UserOptions.replaceUser,
          command: () => { },
          visible: true
        },
        {
          ...UserOptions.removeUser,
          command: () => { },
          visible: true
        }
      ];
      this.userActions.map(item => {
        if (item.label) {
          item.label = this.translate.getTranslation(item.label);
        }
      });
    }
    this.filteredStatuses.map(item => {
      if (item.label) {
        item.label = this.translate.getTranslation(item.label);
      }
    });
  }

  getRolesList() {
    const indexRole = ManagementRoles.filter(role => role.code === this.userRole)[0]?.level;
    if (indexRole) this.filteredRoles = ManagementRoles.filter(role => role.level < indexRole);
  }

  onPageChange(event: PageEvent) {
    this.first = event.first;
    this.pageNum = event.page;
    this.rows = event.rows;
    this.reloadDataEvent.emit({ pageNum: this.pageNum, pageSize: this.pageSize, filterData: this.previousValueFilter, searchData: this.keyword });
  }

  onRowsPerPageChange(event: any) {
    this.rows = event.value; // Update the selected number of rows per page
    this.pageSize = event.value;
    this.reloadDataEvent.emit({ pageNum: this.pageNum, pageSize: this.pageSize, filterData: this.previousValueFilter, searchData: this.keyword });
  }

  handleGeneratePaginatorCurrent(): string {
    return `${this.totalRecords < 1 ? 0 : this.first + 1} - ${this.totalRecords > 0
      ? this.rows + this.first <= this.totalRecords
        ? this.rows + this.first
        : this.totalRecords
      : 0
      }
    of ${this.totalRecords ? this.totalRecords : 0} items`;
  }

  activateUser(user: any) {
    this.selectedRowData = user;
    this.userActions[0] = {
      ...this.userActions[0],
      command: () => {
        this.addModerator(true, user);
      },
      visible: user.node.data.role !== 'COLLABORATORS' ? true : false
    };
    this.userActions[1] = {
      ...this.userActions[1],
      command: () => {
        this.editRoleModerator(user);
      }
    };
    this.userActions[2] = {
      ...this.userActions[2],
      command: () => {
        this.replaceModerator(user);
      }
    };
    this.userActions[3] = {
      ...this.userActions[3],
      command: () => {
        this.isShowDeleteConfirm = true;
      }
    };
  }

  navigate(id: string) {
    this.navigateEvent.emit({ id });
  }

  applyFilter() {
    let usingFilter = false;
    if ((this.filterGroup.controls['selectedRoles'].value && this.filterGroup.controls['selectedRoles'].value.length !== this.filteredRoles.length) || (this.filterGroup.controls['selectedStatuses'].value && this.filterGroup.controls['selectedStatuses'].value.length !== this.filteredStatuses.length)) {
      usingFilter = true;
    }
    const filterData = usingFilter ? this.filterGroup.value : null;
    this.reloadDataEvent.emit({ pageNum: this.pageNum, pageSize: this.pageSize, filterData: filterData, searchData: this.keyword });
    this.previousValueFilter = this.filterGroup.value;
    this.closeDialog();
  }

  closeDialog() { 
    this.visibleFilter = false;
    this.rollbackForm();
  }

  handleOverlayHide() {
    this.rollbackForm();
  }

  rollbackForm() {
    if (this.previousValueFilter !== this.filterGroup.value) {
      this.filterGroup.patchValue(this.previousValueFilter);
    }
  }

  onNodeExpand(event: any) {
    const node = event.node;
    if (!node.children || !node.children.length) {
      const param: ModeratorListRequest = {
        country_code: node.data.country_code,
        node_id: node.data.user_id,
        region: node.data.region,
        city: node.data.city,
        type: node.data.type,
        type_moderator: node.data.type_moderator
      };
      node.children = [];
      this.userService.getModerators(0, 10, param, '', '', '').subscribe(res => {
        res.data.data.forEach((user: any, index) => {
          node.children.push({
            data: user.moderator,
            leaf: !user.moderator.has_child,
            children: [],
            isShowBtn: index === res.data.data.length - 1 && res.data.data.length < res.data.totalElement,
            childLevel: node.parent ? node.childLevel + 1 : 1
          });
        });
        this.data = [...this.data];
      });
    } else if (this.isUpdateChildData) {
      node.children = [...node.children];
      this.data = [...this.data];
      this.isUpdateChildData = false;
    }
  }

  updateModeratorList(event: any, type: string) {
    if (!event) return;
    if (type === ModerateType.add) {
      if (this.selectedRowData) {
        this.selectedRowData.children = this.selectedRowData.node.children && this.selectedRowData.node.children.length ? this.selectedRowData.node.children : [];
        if (this.selectedRowData.children?.length) {
          this.isUpdateChildData = true;
        }
        event.forEach((user: any) => {
          this.selectedRowData.children.push({
            data: user,
            leaf: true,
            children: []
          });
        });
        this.selectedRowData.node.expanded = true;
        this.treeTable.onNodeExpand.emit(this.selectedRowData);
      } else {
        event.forEach((user: any) => {
          this.data.push({
            data: user,
            leaf: !user.has_child,
            children: []
          });
        });
        this.data = [...this.data];
      }
      this.totalRecords += event.length;
      this.totalUpdate.emit(event.length);
    }
    if (type === ModerateType.replace || type === ModerateType.edit) {
      if (this.selectedRowData.parent !== null) {
        this.selectedRowData.parent.children = this.selectedRowData.parent.children.map((el: any) => {
          if (el.data.user_id === event.previousUserId) {
            return {...el, data: type === ModerateType.replace ? event.data[0] : event.data};
          }
          return el;
        });
        this.selectedRowData.node.expanded = true;
      } else {
        this.data = this.data.map(el => {
          if (el.data.user_id === event.previousUserId) {
            return {...el, data: type === ModerateType.replace ? event.data[0] : event.data};
          }
          return el;
        });
      }
      this.data = [...this.data];
    }
    if (type === ModerateType.delete) {
      if (this.selectedRowData.parent !== null) {
        const index = this.selectedRowData.parent.children.findIndex((item: any) => item.data.id === this.selectedRowData.node.data.id);
        this.selectedRowData.parent.children.splice(index, 1);
        if (!this.selectedRowData.parent.children.length) {
          this.selectedRowData.parent.expanded = false;
          this.selectedRowData.parent.leaf = true;
        }
        this.selectedRowData = {...this.selectedRowData};
        this.data = [...this.data];
      } else {
        this.data = this.data.filter(el => el.data.user_id !== this.selectedRowData.node.data.user_id);
      }
      this.totalRecords -= 1;
      this.totalUpdate.emit(-1);
    }
    this.updateModerator.emit(type);
  }

  addModerator(isAssignModerator = false, userInfo?: any) {
    if (!isAssignModerator) {
      this.selectedRowData = null;
    }
    this.moderateType = ModerateType.add;
    if (!userInfo) {
      this.assignedUserId = this.userInfo.id;
      this.assignedUserRole = this.userRole;
      this.assignedUser = '';
    } else {
      this.assignedUserId = userInfo.node.data.user_id;
      this.assignedUserRole = userInfo.node.data.role;
      this.assignedUser = userInfo.node.data.full_name;
    }
    this.addVisible = !this.addVisible;
  }

  replaceModerator(userInfo: any) {
    this.moderateType = ModerateType.replace;
    this.assignedUserId = userInfo.node.data.user_id;
    this.assignedUserRole = userInfo.node.data.role;
    this.assignedUser = '';
    this.addVisible = !this.addVisible;
  }

  editRoleModerator(userInfo: any) {
    this.moderateType = ModerateType.edit;
    this.assignedUser = '';
    if (!userInfo.parent) {
      this.assignedUserId = this.userInfo.id;
      this.assignedUserRole = this.userRole;
    } else {
      this.assignedUserId = userInfo.parent.data.user_id;
      this.assignedUserRole = userInfo.parent.data.role;
    }
    this.addVisible = !this.addVisible;
  }

  deleteModerator(isOk: boolean) {
    if (isOk) {
      // Always have country => default data = 1;
      let numberOfLocation = 1;
      if (this.location.region) numberOfLocation++;
      if (this.location.city) numberOfLocation++;
      const data: AddModeratorRequest = {
        country_code: this.location.country_code,
        region: this.location.region || '',
        city: this.location.city || '',
        type: this.module.parent_module.value,
        type_moderator: this.module.module.value,
        user_ids: [this.selectedRowData.node.data.user_id],
        role: this.selectedRowData.node.data.role,
        parent_id: this.userInfo.id,
        number_of_field_submitted: numberOfLocation
      };
      this.isLoadingDeleteUser = true;
      this.userService.deleteModerator(data).subscribe(res => {
        if (res.success) {
          this.updateModeratorList([], ModerateType.delete);
          this.isShowDeleteConfirm = false;
        }
        this.isLoadingDeleteUser = false;
      })
    } else {
      this.isShowDeleteConfirm = false;
      this.selectedRowData = null;
    }
  }

  searchData() {
    if (this.timeOutData) {
      clearTimeout(this.timeOutData);
    }
    this.timeOutData = setTimeout(() => {
      this.reloadDataEvent.emit({ pageNum: this.pageNum, pageSize: this.pageSize, filterData: this.previousValueFilter, searchData: this.keyword });
    }, 500);
  }

  onSearchTextChange(event: Event){
    const textSearch = (event.target as HTMLInputElement).value;
    this.keyword = textSearch;
  }

  loadMoreChildrenData(event: any) {
    this.isLoadingChild = true;
    const node = event.parent;
    const param: ModeratorListRequest = {
      country_code: node.data.country_code,
      node_id: node.data.user_id,
      region: node.data.region,
      city: node.data.city,
      type: node.data.type,
      type_moderator: node.data.type_moderator
    };
    this.userService.getModerators(node.children.length/10, 10, param, '', '', '').subscribe(res => {
      node.children.map((item: any) => {
        item.isShowBtn = false;
      });
      const totalPrevious = node.children.length;
      res.data.data.forEach((user: any, index) => {
        node.children.push({
          data: user.moderator,
          leaf: !user.moderator.has_child,
          children: [],
          isShowBtn: index === res.data.data.length - 1 && (totalPrevious + res.data.data.length) < res.data.totalElement,
          childLevel: node.parent ? node.childLevel + 1 : 1
        });
      });
      this.data = [...this.data];
      this.isLoadingChild = false;
    }, () => {
      this.isLoadingChild = false;
    });
  }
}
