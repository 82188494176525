<div class="bg-white rounded-md h-full">
  <div class="pt-5 px-3 font-semibold text-lg">
    {{ "APPROVAL_REQUESTS.MODULE_LIST.TITLE" | translate }}
  </div>
  <div class="flex flex-col pt-2 px-3 module-list overflow-y-auto">
    <p-tree
      *ngIf="!isLoading"
      class="flex"
      [styleClass]="'flex w-full h-full px-0 pb-0 pt-4 border-0'"
      [value]="data"
      (onNodeExpand)="nodeExpand($event)"
      (onNodeSelect)="nodeSelect($event)"
      selectionMode="single"
      [lazy]="true"
    >
      <ng-template let-node pTemplate="default">
        <ng-container *ngIf="!node.children; else leafTemplate">
          <div class="cursor-pointer flex gap-2 ml-[-20px] items-center">
            <p-button
              [styleClass]="
                selectedData.data === node.data
                  ? 'border-none rounded-full p-0 w-5 h-5 bg-palette-blue-600'
                  : 'border-palette-gray-300 rounded-full p-0 w-5 h-5 bg-palette-base-white'
              "
              [ngClass]="selectedData.data === node.data ? ' rounded-full' : ''"
              [icon]="
                selectedData.data === node.data
                  ? 'sctr-icon-check text-sm font-semibold text-palette-base-white'
                  : ''
              "
            ></p-button>
            <div>{{ node.label }}</div>
          </div>
        </ng-container>
        <ng-template #leafTemplate>
          {{ node.label }}
        </ng-template>
      </ng-template></p-tree
    >
    <div *ngIf="isLoading" class="mx-2.5 pb-0 pt-6">
      <div class="flex gap-3">
        <i class="sctr-icon-chevron-down text-2xl"></i>
        <p-skeleton height="2rem" width="14rem" styleClass="mb-2"/>
      </div>
      <p-skeleton height="2rem" width="10rem" styleClass="mb-2 ml-9"/>
      <p-skeleton height="2rem" width="10rem" styleClass="mb-2 ml-9"/>
    </div>
  </div>
</div>
