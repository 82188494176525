import { HttpClient } from '@angular/common/http';
import { ApiClientConfig } from './api-client.config';
import { AuthApi } from './auth/api.auth';
import { UserApi } from './user/api.user';
import { CountryApi } from './country-region/api.country';
export class ApiClient {
  readonly auth: AuthApi;
  readonly user: UserApi;
  readonly country: CountryApi;
  constructor(
    private http: HttpClient,
    config: ApiClientConfig,
  ) {
    this.auth = new AuthApi(this.http, config);
    this.user = new UserApi(this.http, config);
    this.country = new CountryApi(this.http, config);
  }
}
