import { environment } from '@env/environment';
import { UserInfo } from './user';

export interface Post {
  id: string;
  comments?: Comment[];
  content: string;
  friend_tags?: FriendTag[];
  group_object?: GroupDetailModel;
  location_id?: string;
  media_urls?: MediaInfo;
  page?: PostPage;
  post_type: string;
  posted_date: string;
  title?: string;
  user_object: UserInfo;
  total_comments?: number;
  total_shares?: number;
  total_reactions?: number;
  total_coins?: number;
  total_reaches?: number;
  post_privacy: string;
  parent?: Post;
  post_pattern: string;
  list_reactions?: Reaction[];
  post_feeling?: string;
  share_link_type?: string;
  object_data?: LinkInfo;
  share_link?: string;
  group_id?: string;
  page_id?: string;
  page_object?: PageObject;
  livestream_info?: LivestreamInfo;
  message_ranges?: MessageRanges[];
  action_type?: string;
  ad_name?: string;
  button_name?: string;
  description?: string;
  destination_url?: string;
  object_id?: string;
  object_type?: string;
  post_id?: string;
  status: string;
  post_info?: Post;
  arr_auto_labeling?: any;
}

export interface PostPage {}

export interface LinkInfo {
  title: string;
  description?: string;
  array_images?: string[];
  avatar: string;
  price?: number;
  original_price?: number;
  price_unit?: string;
  rating?: number;
  hashtag?: string;
  total_reviews?: number;
  sub_title?: string;
}

export interface LivestreamInfo {
  id: string;
  url: string;
}

export interface PageObject {
  avatar: string;
  cover_image_uri: string;
  link: string;
  page_id: string;
  page_name: string;
}
export interface Comment {
  id: string;
  object_id: string;
  object_type: string;
  parent_id: string;
  content: string;
  reactions: Reaction[];
  created_by: UserInfo;
  updated_at: string;
  created_at: string;
  total_replies: number;
  message_ranges?: MessageRanges[];
}

export interface MessageRanges {
  id?: string;
  entity_id: string;
  entity_type: string;
  length: number;
  offset: number;
  full_name?: string;
}

export interface MediaInfo {
  media: Media[];
  layout_type: string;
}

export interface Media {
  type: string;
  thumbnail: string;
  original: string;
}

export interface Reaction {
  type: string;
  icon?: string;
  count: number;
  users?: string[];
}

export interface FriendTag {
  user_object: UserInfo;
  user_id: string;
}

export const FILE_TYPE_URL = {
  thumbnail: 'thumbnail',
  web: 'web',
  mobile: 'mobile',
  streamVideo: 'stream-video'
};

export const checkUrl = (urlString: string, style: string) => {
  if (urlString && (urlString.includes('http:') || urlString.includes('https:'))) {
    return urlString;
  }
  if (urlString && urlString.includes('/storage/files/')) {
    return environment.API_URL + urlString;
  } else return `${environment.API_URL}/storage/files/${style}/${urlString}`;
};

export interface GroupDetailModel {
  group_id: string | null;
  group_name?: string | null;
  description?: string | null;
  privacy?: string | null;
  location?: string | null;
  email?: string | null;
  number_phone?: string | null;
  address?: string | null;
  question?: string[] | [];
  rules?: RuleModel[] | [];
}

export interface RuleModel {
  name: string;
  content: string;
}
