<div [ngStyle]="{ 'width': avatarSize + 'px', 'rounded-full ring-2 ring-white': isShowFrame }">
  <div class="rounded-full p-[2px] ring-2 ring-orange-500" *ngIf="isShowFrame; else avatar">
    <ng-container *ngTemplateOutlet="avatar"></ng-container>
  </div>
</div>

<ng-template #avatar>
  <div (click)="navigateToProfile()" class="relative" [ngClass]="readOnly ? '' : 'cursor-pointer'"
    [ngStyle]="{ 'width': avatarSize - 4 + 'px', 'height': avatarSize - 4 + 'px' }">
    <div class="rounded-full ring-2 ring-transparent"
      [ngStyle]="{ 'width': avatarSize - 4 + 'px', 'height': avatarSize - 4 + 'px' }">
      <img class="rounded-full w-full h-full" *ngIf="userAvatarUrl"
        onerror="this.src='assets/images/default_user_avatar.png'" [src]="userAvatarUrl" />
      <img onerror="this.style.display = 'none'" *ngIf="userFrameUrl" class="rounded-full absolute top-0"
        [src]="userFrameUrl" />
    </div>
  </div>
</ng-template>