// zoomable-world-map.directive.ts
import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import * as d3 from 'd3';
import * as topojson from 'topojson-client';

@Directive({
  selector: '[appZoomableWorldMap]'
})
export class ZoomableWorldMapDirective implements OnInit {
  @Input() width: number = 800;
  @Input() height: number = 500;

  private svg: any;
  private projection: any;
  private path: any;

  constructor(private el: ElementRef) { }

  ngOnInit(): void {
    this.createMap();
  }

  private createMap(): void {
    this.svg = d3.select(this.el.nativeElement).append('svg')
    .attr("viewBox", [0, 0, this.width, this.height]);

    this.projection = d3.geoNaturalEarth1()
      .center([0, 5 ])
      .scale(150)
      .translate([this.width / 2, this.height / 2]);

    this.path = d3.geoPath().projection(this.projection);

    // Load GeoJSON data
    d3.json('../../../assets/mock/map.json').then((world: any) => {
      // Draw countries

        this.svg.selectAll('path')
          .data(world.features)
          .enter().append('path')
          .attr('d', this.path)
          .style('fill', '#ccc')
          .style('stroke', '#fff')
          .style('stroke-width', 0.5);

        // Add zoom behavior
        this.svg.call(d3.zoom()
          .scaleExtent([2, 5])
          .on('zoom', (event) => this.zoomed(event)));
    });
  }

  private zoomed(event: any): void {
    this.svg.selectAll('path')
      .attr('transform', event.transform);
  }
}