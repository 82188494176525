<p-dropdown
  [options]="countriesList"
  [(ngModel)]="selectedCountry"
  optionLabel="country"
  class="mx-4 flex"
  (onChange)="updateRegion($event)"
>
  <ng-template let-country pTemplate="item">
    <div class="flex w-80 gap-2">
      <div>{{ country.country }}</div>
    </div>
  </ng-template>
</p-dropdown>
<p-tree
  *ngIf="!isLoading"
  class="flex"
  [styleClass]="'flex w-full h-full px-0 pb-0 pt-4 border-0'"
  [ngClass]="isModerator ? 'countries-tree' : ''"
  [value]="countriesTree"
  [(selection)]="selectedPlace"
  (onNodeSelect)="nodeSelect($event)"
  selectionMode="single"
>
  <ng-template let-node pTemplate="default">
    <div class="gap-2 flex flex-row">
      <span class="text-nowrap">{{ getRegionLabel(node.label, true) }}</span>
      <span class="w-full bg-palette-gray-100 rounded-full px-2 h-min">{{
        getRegionLabel(node.label, false)
      }}</span>
    </div>
  </ng-template>
</p-tree>
<div *ngIf="isLoading" class="mx-4 pb-0 pt-4">
  <div class="flex gap-3">
    <i class="sctr-icon-chevron-down text-2xl"></i>
    <p-skeleton height="26px" width="14rem" styleClass="mb-2"/>
  </div>
  <p-skeleton height="26px" width="10rem" styleClass="mb-2 ml-9"/>
  <p-skeleton height="26px" width="10rem" styleClass="mb-2 ml-9"/>
</div>
