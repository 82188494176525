<div class="justify-content-center flex">
  <p-dialog
    #addDialog
    header="Header"
    [modal]="true"
    [(visible)]="addVisible"
    [draggable]="false"
    [resizable]="false"
    [style]="{ width: '450px' }"
    (onHide)="closeDialog()"
    [header]="(type === moderateType.add ? 'USER_MANAGEMENT.ADD_USER' : type === moderateType.replace ?  'USER_MANAGEMENT.REPLACE_USER' : 'USER_MANAGEMENT.EDIT_ROLE') | translate"
  >
    <div class="mt-3 flex flex-col gap-1">
      <label class="text-ellipsis text-palette-gray-700 font-medium text-sm">{{
        "USER_MANAGEMENT.LOCATION" | translate
      }}</label>
      <input
        class="w-full bg-palette-gray-300 text-palette-gray-900"
        [value]="locationText()"
        pInputText
        type="text"
        [disabled]="true"
      />
    </div>
    <div class="mt-3 flex flex-col gap-1">
      <label class="text-ellipsis text-palette-gray-700 font-medium text-sm">{{
        "USER_MANAGEMENT.MODULE" | translate
      }}</label>
      <input
        class="w-full bg-palette-gray-300 text-palette-gray-900"
        [value]="moduleText(module)"
        pInputText
        type="text"
        [disabled]="true"
      />
    </div>
    <div class="mt-3 flex flex-col gap-1" *ngIf="assignedUser !== ''">
      <label class="text-ellipsis text-palette-gray-700 font-medium text-sm">{{
        "USER_MANAGEMENT.ASSIGN_FOR" | translate
      }}</label>
      <input
        class="w-full bg-palette-gray-300 text-palette-gray-900"
        [value]="assignedUser"
        pInputText
        type="text"
        [disabled]="true"
      />
    </div>
    <div class="mt-3 flex flex-col gap-1">
      <label class="text-ellipsis text-palette-gray-700 font-medium text-sm">{{
        "USER_MANAGEMENT.USERS" | translate
      }}</label>
      <p-autoComplete
        *ngIf="type !== moderateType.edit"
        #autoComplete
        [(ngModel)]="selectUsers"
        (completeMethod)="this.keySearch = $event.query"
        [suggestions]="userSuggestions"
        [multiple]="type === moderateType.replace ? false : true"
        (onKeyUp)="searchInput($event)"
        (click)="getUserList(keySearch)"
        field="full_name"
        styleClass="w-full max-h-[230px]"
        panelStyleClass="min-h-[68px]"
        [appendTo]="'body'"
        [autoHighlight]="true"
        [showEmptyMessage]="keySearch !== '' ? true : false"
        [virtualScroll]="true"
        [virtualScrollItemSize]="68"
        [virtualScrollOptions]="options"
        (onHide)="hide($event)"
      >
        <ng-template
          let-item
          class="p-0"
          pTemplate="item"
          pTemplate="selectedItem"
        >
          <div class="flex gap-2 items-center">
            <app-avatar
              [avatarSize]="20"
              [userInfo]="item"
              [isShowFrame]="false"
              [readOnly]="true"
            />
            <div class="text-palette-gray-700 font-normal text-sm">
              {{ item.full_name }}
            </div>
          </div>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <div class="flex items-start justify-between w-full max-w-[356px]">
            <div
              class="flex gap-2 items-start text-sm font-medium text-palette-gray-700 truncate"
            >
              <app-avatar
                [avatarSize]="28"
                [userInfo]="item"
                [isShowFrame]="false"
                [readOnly]="true"
              />
              <div class="flex flex-col truncate">
                <div class="text-sm font-medium text-palette-gray-700 truncate">
                  {{ item.full_name }}
                </div>
                <div class="text-sm font-normal text-palette-gray-500">
                  {{ item.email }}
                </div>
              </div>
            </div>
            <i
              *ngIf="isShowCheckUsers(item.id)"
              class="pi pi-check"
              style="color: #1570ef"
            ></i>
          </div>
        </ng-template>
      </p-autoComplete>
      <input
        *ngIf="type === moderateType.edit && editUser && editUser.full_name"
        class="w-full bg-palette-gray-300 text-palette-gray-900"
        [value]="editUser.full_name"
        pInputText
        type="text"
        [disabled]="true"
      />
    </div>
    <div class="mt-3 flex flex-col gap-1">
      <label class="text-ellipsis text-palette-gray-700 font-medium text-sm">{{
        "USER_MANAGEMENT.ROLE" | translate
      }}</label>
      <p-dropdown
        *ngIf="type !== moderateType.replace"
        [options]="rolesList"
        [(ngModel)]="selectedRole"
        [appendTo]="addDialog"
        optionLabel="name"
        [placeholder]="'USER_MANAGEMENT.SELECT_ROLE' | translate"
        styleClass="rounded-lg w-full"
        optionValue="code"
      >
        <ng-template let-role pTemplate="item">
          <div
            class="dropdown-list"
            class="flex items-center justify-between w-full"
          >
            <div
              class="flex gap-2 items-center text-sm font-medium text-palette-gray-700"
            >
              <div>{{ role.name }}</div>
            </div>
            <i
              *ngIf="selectedRole === role.code"
              class="pi pi-check"
              style="color: #1570ef"
            ></i>
          </div>
        </ng-template>
      </p-dropdown>
      <input
      *ngIf="type === moderateType.replace"
        class="w-full bg-palette-gray-300 text-palette-gray-900"
        [value]="this.selectedRole"
        pInputText
        type="text"
        [disabled]="true"
      />
    </div>
    <ng-template pTemplate="footer">
      <p-button
        styleClass="h-11 p-button-secondary justify-center"
        (onClick)="closeDialog()"
        ><span class="text-palette-gray-700 text-base font-semibold">{{
          "COMMON.CANCEL" | translate
        }}</span></p-button
      >
      <p-button
        styleClass="h-11 bg-palette-blue-600 justify-center"
        (onClick)="add()"
        [disabled]="(type === moderateType.replace ? !this.selectUsers : type !== moderateType.edit && !this.selectUsers.length) || !this.selectedRole || isLoading" 
        >
          <i *ngIf="isLoading" class="pi-spin pi sctr-icon-loading-02 mr-2"></i>
          <span class="text-palette-base-white text-base font-semibold">{{
            (type === moderateType.add ? "USER_MANAGEMENT.ADD_USER" :
            (type === moderateType.add ? "USER_MANAGEMENT.REPLACE_USER"
            : "USER_MANAGEMENT.EDIT_ROLE")) | translate
            }}
          </span>
        </p-button
      >
    </ng-template>
  </p-dialog>
</div>
